import React from "react"
import {
  ContactPageContainer,
  MapsContainer,
  MapModule,
  MapImg,
  MapText,
} from "./style"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"
import { ContactForm } from "components/ContactForm"
import useMedia from "hooks/useMedia.js"
import { GetLocationsData } from "utils/locationsData"

const ContactPage = () => {
  const locationsData = GetLocationsData()

  const isMobile = useMedia(
    // Media queries
    ["(max-width: 767px)"],
    //options
    [true],
    // default
    false
  )

  return (
    <ContactPageContainer>
      <FlexGrid gap={[35, 40, 60]}>
        <FlexRow>
          <FlexCol xs={12} md={8}>
            <ContactForm />
          </FlexCol>
          <FlexCol xs={12} md={4}>
            <h4>Find us</h4>

            <p className="findUs">
              Voice operates across Asia-Pacific, working with clients globally.{" "}
            </p>
            <MapsContainer>
              {locationsData.map((location, index) => {
                return (
                  <MapModule key={index} first={index === 0}>
                    <MapImg fluid={location.img} />
                    <MapText>
                      <h4>{location.title}</h4>
                      <p
                        className="address"
                        dangerouslySetInnerHTML={{
                          __html: location.address,
                        }}
                      />

                      {location.people.map((person, index) => {
                        return (
                          <div className="person" key={index}>
                            <span className="name">{person.name}</span>
                            <div className="number">
                              <span>P</span>
                              {isMobile ? (
                                <a href={`tel:${person.mobile}`}>
                                  {person.mobile}
                                </a>
                              ) : (
                                <p>{person.mobile}</p>
                              )}
                            </div>
                            <a
                              className="email"
                              href={`mailto:${person.email}`}
                            >
                              Email {person.name.split(" ", 2)[0]}
                            </a>
                          </div>
                        )
                      })}
                    </MapText>
                  </MapModule>
                )
              })}
            </MapsContainer>
          </FlexCol>
        </FlexRow>
      </FlexGrid>
    </ContactPageContainer>
  )
}

export default ContactPage
